export const PARTNER_ID_HEAVEN_LIGHT = 86
export const PARTNER_ID_MAP_CLUB = 46

export const LAYANAN_OPTIONS = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'BBM',
    value: 'BBM',
  },
  {
    label: 'BEST',
    value: 'BEST',
  },
  {
    label: 'CARGO',
    value: 'CARGO',
  },
  {
    label: 'DOK',
    value: 'DOK',
  },
  {
    label: 'EKO',
    value: 'EKO',
  },
  {
    label: 'GOKIL',
    value: 'GOKIL',
  },
  {
    label: 'HALU',
    value: 'HALU',
  },
  {
    label: "KEPO",
    value: "KEPO",
  },
  {
    label: 'PACK',
    value: 'PACK',
  },
  {
    label: 'REG',
    value: 'REG',
  },
  {
    label: 'SDS',
    value: 'SDS',
  },
  {
    label: 'SIUNTUNG',
    value: 'SIUNT',
  },
];