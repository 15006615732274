
import { observer } from 'mobx-react';
import qs from 'qs';
import React from 'react';
import { RouterProps } from 'react-router';
import * as AWS from 'aws-sdk';
import { Button } from 'primereact/button';
import { Redirect } from 'react-router-dom';
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";

@observer
export class AwbImageContainer extends React.Component<any, any> {
  awbNumber: string;
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  s3Image: any;
  constructor(props, context) {
    super(props, context);

    this.state = {
      imageLoading: '',
      imageNotFound: false
    }
  }

  componentWillMount() {
    this.awbNumber = this.props.match.params!.awb;
    this.loadImage();
  }

  encode(data) {
    var str = data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
    return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
  }

  loadImage() {
    this.setState({
      imageLoading: true
    });

    // AWS.config.update({
    //   accessKeyId: 'AKIAIVQFSDMYQAALZ66Q', secretAccessKey: '3lFYlppcbLJ+SXfKddNrdk0e4ROBFUnxNHvSjt8z'
    // });
    // AWS.config.region = "us-east-1";

    // const params = {
    //   Bucket: 'sicepatresi',
    //   Key: this.awbNumber.substring(0, 7) + '/' + this.awbNumber + '.jpg'
    // };

    // const bucket = new AWS.S3();
    // bucket.getObject(params, (err, data) => {
    //   if (err) {
    //     this.setState({
    //       imageLoading: false,
    //       imageNotFound: true
    //     });
    //     this.s3Image = '';
    //   } else {
    //     this.s3Image = "data:image/png;base64," + this.encode(data.Body);
    //     this.setState({
    //       imageLoading: false
    //     });
    //   }
    // });

    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
      : null;
      if (userData === null) {
        return <Redirect to={"/auth/login"} />;
      }
    const branchCode = localStorage.getItem('branchCode')
    this.monitoringRestService
      .post("awb-secure", {
        awb_number: this.awbNumber,
        field: "img_awb",
        user_id: String(userData.user_id),
        branch_code: branchCode
      })
      .subscribe(
        response => {
          this.s3Image = response.data;
          let imageNotFound = false;
          if (response.data === "-") {
            imageNotFound = true;
          }
          this.setState({
            imageLoading: false,
            imageNotFound: imageNotFound,
          })
        },
        (err) => {
          this.setState({
            imageLoading: false,
            imageNotFound: true,
          });
         },
        () => { },
      );


  }

  render() {
    if (localStorage.getItem("userData") === null) {
      return <Redirect to={"/auth/login"} />;
    }

    return (
      <div>
        {(this.state.imageLoading) && <p>Loading...</p>}
        {(this.state.imageNotFound) ?
          <h3>Gambar tidak dapat ditemukan.</h3> : 
          <img style={{ pointerEvents: "none" }} src={this.s3Image} />
        }
      </div>
    );
  }
}
